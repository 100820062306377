<template>
  <Toast></Toast>
  <Dialog
    v-model:visible="mostrarModal"
    :style="{ width: '550px' }"
    header="Pago a Proveedor"
    :modal="true"
    @hide="closeModal"
    class="p-fluid"
  >
    <div class="col-12">
      <div class="p-fluid formgrid grid">
        <div class="field col-12 md:col-3">
          <span><strong>FACTURA: # </strong>{{ data_pago.nro_recibo }}</span>
        </div>
        <div class="field col-12 md:col-3">
          <span
            ><strong>TOTAL: </strong>
            {{ convertirNumeroGermanicFormat(data_pago.monto_total) }}
            Bs.</span
          >
        </div>
        <div class="field col-12 md:col-3">
          <span
            ><strong>A CUENTA: </strong>
            {{ convertirNumeroGermanicFormat(data_pago.a_cuenta) }} Bs.</span
          >
        </div>
        <div class="field col-12 md:col-3">
          <span
            ><strong>SALDO: </strong>
            {{
              convertirNumeroGermanicFormat(
                data_pago.saldo - monto - a_cuenta_metodo_pago
              )
            }}
            Bs.</span
          >
        </div>
      </div>
      <div class="p-fluid formgrid grid">
        <div class="field col-12 md:col-4">
          <span
            ><strong>M&Eacute;TODO DE PAGO:</strong
            ><small class="p-invalid">*</small></span
          >
          <Dropdown
            v-model="metodo_pagoSelect"
            :options="metodo_pagos"
            optionLabel="nombre"
            class="w-full"
            optionValue="id"
            placeholder="Seleccione..."
          />
          <small class="p-invalid" v-if="errors.metodo_pagoSelect">{{
            errors.metodo_pagoSelect
          }}</small>
        </div>
        <div class="field col-12 md:col-4" v-if="metodo_pagoSelect != 1">
          <span><strong>MONTO M.PAGOS:</strong></span>
          <InputNumber
            v-model="a_cuenta_metodo_pago"
            :minFractionDigits="2"
            mode="decimal"
            showButtons
            :inputClass="'text-right'"
            :min="0"
            :max="parseFloat(data_pago.saldo - monto)"
          />
          <small class="p-invalid" v-if="errors.a_cuenta_metodo_pago">{{
            errors.a_cuenta_metodo_pago
          }}</small>
        </div>
        <div class="field col-12 md:col-4">
          <span
            ><strong>EFECTIVO BS:</strong
            ><small class="p-invalid">*</small></span
          >
          <InputNumber
            v-model="monto"
            :minFractionDigits="2"
            mode="decimal"
            showButtons
            :inputClass="'text-right'"
            :min="0"
            :max="parseFloat(data_pago.saldo - a_cuenta_metodo_pago)"
          />
          <small class="p-invalid" v-if="errors.monto">{{
            errors.monto
          }}</small>
        </div>
        <div
          v-if="'Banco Listar' in auth.user.permissions"
          :class="
            enviar_a_banco ? 'field col-12 md:col-6' : 'field col-12 md:col-4'
          "
        >
          <span>&nbsp;</span>
          <div class="p-inputgroup">
            <span class="p-inputgroup-addon">
              <span>DE BANCO:</span>
              <Checkbox
                v-model="enviar_a_banco"
                :binary="true"
                @change="cargarBancos"
                v-tooltip.top="'Enviar a Banco'"
              />
            </span>
            <Dropdown
              v-if="enviar_a_banco"
              v-model="bancoSelected"
              :options="bancos"
              optionLabel="descripcion"
              optionValue="id"
              placeholder="Seleccione..."
              :class="{ 'p-invalid': errors.bancoSelected }"
              @change="obtenerSaldoActual"
            >
              <template #option="slotProps">
                <span class="font-weight-bold">
                  {{ slotProps.option.numero_cuenta ?? "" }} -
                  {{ slotProps.option.descripcion ?? "" }}
                </span>
              </template>
            </Dropdown>
          </div>
          <small class="p-invalid" v-if="errors.bancoSelected">{{
            errors.bancoSelected
          }}</small>
        </div>
        <div
          :class="
            enviar_a_banco ? 'field col-12 md:col-6' : 'field col-12 md:col-12'
          "
        >
          <span><strong>OBSERVACI&Oacute;N:</strong></span>
          <Textarea
            v-model="data_pago.observacion"
            :autoResize="true"
            :rows="1"
            placeholder="observacion"
          />
        </div>
      </div>
    </div>
    <template #footer>
      <div class="field">
        <span class="flex md:justify-content-start"
          ><strong>CAMPOS REQUERIDOS:</strong
          ><span class="p-invalid">*</span></span
        >
      </div>
      <Button
        label="CANCELAR"
        icon="pi pi-times"
        class="p-button-secondary p-button-lg"
        @click="closeModal"
      />
      <Button
        label="PAGAR"
        icon="pi pi-save"
        class="p-button-info p-button-lg"
        @click="guardarPagoProveedor"
        :loading="enviado"
      />
    </template>
  </Dialog>
</template>
<script>
import PagoProveedorService from "@/service/PagoProveedorService.js";
import MetodoPagoService from "@/service/MetodoPagoService.js";
import BancoService from "@/service/BancoService";
import { useAuth } from "@/stores";

export default {
  name: "PagoProveedor",
  emits: ["closeModal", "actualizarListado"],
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    pago_proveedor: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      mostrarModal: this.show,
      data_pago: this.pago_proveedor,
      enviado: false,
      errors: {},
      metodo_pagos: [],
      metodo_pagoSelect: null,
      a_cuenta_metodo_pago: 0,
      monto: 0,
      bancoSelected: null,
      bancos: [],
      enviar_a_banco: false,
      saldo_actual: 0,
    };
  },
  pagoProveedorService: null,
  metodoPagoService: null,
  bancoService: null,
  auth: null,

  created() {
    this.pagoProveedorService = new PagoProveedorService();
    this.metodoPagoService = new MetodoPagoService();
    this.bancoService = new BancoService();
    this.auth = useAuth();
  },
  mounted() {
    this.getMetodoPagos();
  },
  methods: {
    obtenerSaldoActual() {
      this.bancos.forEach((element) => {
        if (element.id == this.bancoSelected) {
          this.saldo_actual = element.saldo_actual;
        }
      });
    },
    cargarBancos() {
      this.bancoService.getBancosAll().then((data) => {
        this.bancos = data.bancos;
      });
    },
    getMetodoPagos() {
      this.metodoPagoService.getAllMetodoPagos().then((response) => {
        this.metodo_pagos = response.metodo_pagos;
      });
    },
    validarFormulario() {
      this.errors = {};

      let total_debitar =
        parseFloat(this.monto) + parseFloat(this.a_cuenta_metodo_pago);
      if (this.saldo_actual < total_debitar && this.enviar_a_banco) {
        this.errors.monto = "El monto a debitar es mayor al saldo actual";
      }
      if (this.metodo_pagoSelect == null) {
        this.errors.metodo_pagoSelect = "El campo metodo de pago es requerido";
      }
      if (this.metodo_pagoSelect == 1 && this.monto == 0) {
        this.errors.monto = "El campo monto es requerido";
      }

      if (this.metodo_pagoSelect != 1 && this.a_cuenta_metodo_pago == 0) {
        this.errors.a_cuenta_metodo_pago =
          "El campo monto a cuenta metodo de pago es requerido";
      }

      if (this.enviar_a_banco && this.bancoSelected == null) {
        this.errors.bancoSelected = "El campo banco es requerido";
      }

      return Object.keys(this.errors).length == 0;
    },

    guardarPagoProveedor() {
      if (!this.validarFormulario()) {
        return;
      }
      this.errors = {};
      this.enviado = true;
      let datos = {
        id: this.data_pago.id,
        monto: parseFloat(this.monto),
        saldo: parseFloat(this.data_pago.saldo),
        metodo_pago_id: this.metodo_pagoSelect, // Save the id of the selected option
        a_cuenta_metodo_pago: parseFloat(this.a_cuenta_metodo_pago ?? 0),
        observacion: this.data_pago.observacion,
        enviar_a_banco: this.enviar_a_banco,
        banco_id: this.bancoSelected,
        saldo_actual: this.saldo_actual,
      };

      this.pagoProveedorService
        .actualizoPagoProveedor(datos)
        .then((response) => {
          if (response.success == false) {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.message,
              life: 3000,
            });
            this.enviado = false;
          } else {
            this.$toast.add({
              severity: "success",
              summary: "Pago a Proveedor",
              detail: response.message,
              life: 3000,
            });
            this.enviado = false;
            this.$emit("actualizarListado");
            this.closeModal();
          }
        });
    },
    closeModal() {
      this.$emit("closeModal");
      this.enviado = false;
      this.monto = 0;
      this.a_cuenta_metodo_pago = 0;
      this.metodo_pagoSelect = null;
      this.observacion = "";
      this.enviar_a_banco = false;
      this.bancoSelected = null;
      this.saldo_actual = 0;
      this.errors = {};
    },
    convertirNumeroGermanicFormat(numero) {
      return new Intl.NumberFormat("de-DE", {
        minimumFractionDigits: 2,
      }).format(numero);
    },
  },

  watch: {
    show(value) {
      this.mostrarModal = value;
    },
    pago_proveedor(val) {
      this.data_pago = val;
    },
    metodo_pagoSelect() {
      if (this.metodo_pagoSelect == 1) {
        this.monto = parseFloat(this.data_pago.saldo);
        this.a_cuenta_metodo_pago = 0;
      } else {
        this.a_cuenta_metodo_pago = parseFloat(this.data_pago.saldo);
        this.monto = 0;
      }
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/demo/badges.scss";

.p-invalid {
  color: red;
}
.p-inputgroup-addon {
  background-color: #ffffff;
  font-weight: bold;
  color: #000000;
  border: none;
}
</style>
